import React from "react";

import { HeadingWIcon, Card } from "@molecules";
import { Text, Image, Container } from "@atoms";
import { AppLink } from "@base";

const IssuesGrid = ({
  heading,
  icon,
  copy,
  image,
  issues,
  backgroundImage,
}) => {
  return (
    <div className="relative pt-32 pb-24 sm:pt-48">
      <div className="absolute inset-0 z-0">
        <div className="home-hero absolute inset-0 z-10" />
        <div className="absolute inset-0 z-0 opacity-30">
          {backgroundImage && <Image image={backgroundImage} fill />}
        </div>
      </div>
      <div className="relative pt-6">
        <Container>
          <HeadingWIcon heading={heading} icon={icon} className="mb-4" />
          <Text className="font-serif text-2xl leading-tight">{copy}</Text>
          <div className="-mx-2 mt-12 flex flex-wrap lg:-mx-3">
            {issues.map(issue => {
              return (
                <div
                  key={issue.url}
                  className="mb-4 w-1/2 px-2 xxs:w-full sm:w-1/3 lg:mb-6 lg:px-3"
                >
                  <AppLink to={issue.url} className="group">
                    <Card heading={issue.heading} image={issue.metaImage} />
                  </AppLink>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
    </div>
  );
};

IssuesGrid.defaultProps = {};

export default IssuesGrid;
